import React, { useEffect, useState } from "react";
import dataFetch from "./modules/dataFetch";
import "../styles/AllUserControl.css";
import { baseURL } from "../utils";

function AllUserControl() {
  let [allUsers, setAllUsers] = useState();
  let [showTable, setShowTable] = useState(false);
  let [showFundAccount, setShowFundAccount] = useState(false);
  let [showDeductAccount, setShowDeductAccount] = useState(false);
  let [fundValue, setFundValue] = useState(0);
  let [addingFund, setAddingFunds] = useState(false);
  let [deductingFund, setDeeductiongFunds] = useState(false);
  let [loadingUser, setLoadingUsers] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  // Step 3: Handle change event
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // useEffect(() => {
  //     const fData = new FormData();
  //     fData.append('loadUsers', "yes");
  //     console.log(fData)

  //     const url = baseURL;
  //     dataFetch(url, fData)
  //         .then(data => {
  //             console.log(data)
  //             setAllUsers(JSON.parse(data))
  //         })
  //         .catch(error => {
  //             console.error(error);
  //         });
  //     console.log(allUsers)

  // }, [])

  async function LoadUsers() {
    const fData = new FormData();
    fData.append("loadUsers", "yes");
    setLoadingUsers(true);

    const url = baseURL;
    let result = await dataFetch(url, fData);
    setAllUsers(JSON.parse(result));
  }

  function performTask(task, index) {
    const fData = new FormData();
    fData.append(task, "yes");
    fData.append("email", index);

    const url = baseURL;
    dataFetch(url, fData)
      .then((data) => {})
      .catch((error) => {
        console.error(error);
      });
  }

  function addFunds(email) {
    console.log(email);
    const fData = new FormData();
    fData.append("addFunds", "yes");
    fData.append("email", email);
    fData.append("amount", fundValue);
    fData.append("plan", selectedOption)
    const url = baseURL;
    setAddingFunds(true);
    dataFetch(url, fData)
      .then((data) => {
        if ((data = "message-sent")) {


            
          setAddingFunds(false);
          setFundValue(0);
          alert("Use-Credited");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function removeFunds(email) {
    //console.log(email)
    const fData = new FormData();
    fData.append("removefunds", "yes");
    fData.append("email", email);
    fData.append("amount", fundValue);
    const url = baseURL;
    setDeeductiongFunds(true);
    dataFetch(url, fData)
      .then((data) => {
        console.log(data);
        if (data === "actioncompleted") {
          setDeeductiongFunds(false);
          setFundValue(0);

          alert("user debited");
        } else if ((data = "insufficient-balance")) {
          alert("insufficient balance");
          setDeeductiongFunds(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <button
        onClick={() => {
          LoadUsers().then(() => {
            setLoadingUsers(false);
            setShowTable(!showTable);
            // console.log(allUsers)
          });
        }}
      >
        {showTable ? (
          "Hide table"
        ) : loadingUser ? (
          <i class="fa-solid fa-spinner fa-spin"></i>
        ) : (
          "Show Table"
        )}
      </button>

      {showTable ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Accounts</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((user) => (
                <tr key={user.Email}>
                  <td>
                    <div className="allUSerDataContainer">
                      <div className="userAcount">
                        <h3>{user.Email}</h3>
                        <div>
                          <input
                            type="submit"
                            onClick={(e) => {
                              if (user.AccountEnabled == "TRUE") {
                                performTask("disableAccount", user.Email);
                                user.AccountEnabled = "FALSE";
                                e.target.value = "Enable Account";
                              } else {
                                performTask("enableAccount", user.Email);
                                user.AccountEnabled = "TRUE";
                                e.target.value = "Disable Account";
                              }
                            }}
                            value={
                              user.AccountEnabled == "TRUE"
                                ? "Disable Account"
                                : "Enable Account"
                            }
                          />
                        </div>
                        <button
                          style={{
                            marginRight: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                          onClick={() => {
                            setShowFundAccount(true);
                          }}
                        >
                          Fund User
                        </button>
                        <button
                          style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          onClick={() => {
                            setShowDeductAccount(true);
                          }}
                        >
                          Remove Funds
                        </button>

                        {showFundAccount ? (
                          <div>
                            <p>Fund this user</p>
                            <input
                              type="number"
                              placeholder="amount"
                              value={fundValue}
                              onChange={(e) => {
                                setFundValue(e.target.value);
                              }}
                            />
                            <p>Select Plan</p>
                            <select
                              id="options"
                              value={selectedOption}
                              onChange={handleChange}
                            >
                              <option value="">
                                --Please choose an option--
                              </option>
                              <option value="basic">Basic</option>
                              <option value="professional">Professional</option>
                              <option value="gold">Gold</option>
                              <option value="diamond">Diamond</option>
                            </select>
                            {/* Display the selected option */}
                            {selectedOption && (
                              <p>You selected: {selectedOption}</p>
                            )}
                            <button
                              onClick={() => {
                                if (fundValue == 0) {
                                    alert("Please add amount");
                                } 
                                else if (selectedOption=""){
                                    alert("Please a plan");
                                }
                                else {
                                  if (!addingFund) {
                                    addFunds(user.Email);
                                  }
                                }
                              }}
                            >
                              {addingFund ? "...Funding-user" : "Fund Account"}
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}

                        {showDeductAccount ? (
                          <div>
                            <p>Deduct from user</p>
                            <input
                              type="number"
                              placeholder="amount"
                              value={fundValue}
                              onChange={(e) => {
                                setFundValue(e.target.value);
                              }}
                            />
                            <button
                              onClick={() => {
                                if (fundValue == 0) {
                                  alert("Please add amount");
                                } else {
                                  if (!deductingFund) {
                                    removeFunds(user.Email);
                                  }
                                }
                              }}
                            >
                              {deductingFund
                                ? "...Deducting user funds"
                                : "Deduct funds"}
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="userAccountDetails">
                        <div className="UserName">
                          <h1>Names Details</h1>
                          <p>
                            <b>First Name:</b> {user.FName}
                          </p>
                          <p>
                            <b>Last Name:</b> {user.LName}
                          </p>
                        </div>
                        <div className="userContacts">
                          <h1>Address Details:</h1>
                          <p>
                            <b>Country:</b> {user.Country}
                          </p>
                          <p>
                            <b>State:</b> {user.ST}
                          </p>
                          <p>
                            <b>Address:</b> {user.AD}
                          </p>
                        </div>
                        <div className="Investments">
                          <h1>Investment Details:</h1>
                          <p>
                            <b>InvestMentEnabled:</b> {user.InvestMentEnabled}
                          </p>
                          <p>
                            <b>Investment Date:</b> {user.investmentDate}
                          </p>
                          <p>
                            <b>Investment Plan:</b> {user.investmentPlan}
                          </p>
                          <p>
                            <b>First Billing:</b>{" "}
                            <input
                              className="action-display-button"
                              type="submit"
                              onClick={(e) => {
                                if (user.firstBillingEnabled == "FALSE") {
                                  performTask("enableFirstBilling", user.Email);
                                  e.target.value = "TRUE";
                                }
                              }}
                              value={user.firstBillingEnabled}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        "Not showing table"
      )}
    </div>
  );
}

export default AllUserControl;
