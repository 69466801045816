import React from 'react'

import Footer from "../components/Footer";
import TradingComponents from '../components/TradingComponents';
import "../styles/Home.css"
import heroImage from "../images/heroimg.webp"
import questImage from "../images/quest.png"
import OptionCard from '../components/OptionCard';
import PhonesImage from "../images/twophones.png"
import { useSelector, useDispatch } from 'react-redux';
import { removeproduct, addproduct, removeAllProduct } from '../redux/generalCart';
import { Link } from 'react-router-dom';
import stock from "../images/stock.png"
import livechat from "../images/live-chat.png"
import trade from "../images/trade.png"
import stairs from "../images/stairs.png"
import wificonnection from "../images/wifi-connection.png"

import mordern from "../images/mordern.webp"
import choppas from "../images/choppas.webp"
import friendly from "../images/friendly.png"
import whatsnew from "../images/whatsnew.webp"
import service from "../images/servicesimage5.jpg"




export default function Home() {






















  const generalCart = useSelector((state) => state.generalCart.generalCart);
  const dispatch = useDispatch();
  class Product {
    constructor(name, quantity, price, image) {
      this.name = name || ""
      this.quantity = quantity || 0
      this.price = price || 0
      this.image = image || ""
    }
  }

  let CHOPPA = new Product();
  CHOPPA.name = "CHOPPA(version S)";
  CHOPPA.price = "$300";
  CHOPPA.quantity = 1;
  CHOPPA.image = "../images/fximage.png";

  let autopilotpro = new Product();
  autopilotpro.name = "AutoPilot Pro PLUS+(version w19)";
  autopilotpro.price = "$180";
  autopilotpro.quantity = 1;
  autopilotpro.image = "../images/fximage.png";

  let ourcommunitytext = (text) => {
    return (
      <div>
        <div><p>{text}</p></div>
        <div><button className='siteButton' ><a href="https://t.me/+voei-i3K66owODk0">Join Our  Community</a> </button></div>
      </div>
    )
  }

  return (
    <div className='Home-div'>
      <div className='section1-container'>

        <div className='hero-section'>
          <h1 className='section-header'>CHOPPA  S</h1>
          <p className='section-description'>Make Money Smart, not hard</p>
          <p className='section-description2'>We will show you how</p>
          <img src={heroImage} alt='hero-image' className='.hero-image' />
          <div className='hero-buy'>
            <button className='siteButton hero-button' onClick={() => {
              dispatch(removeAllProduct())
              dispatch(addproduct(CHOPPA))
              window.location.assign("/checkout");
            }}>Get Started <i class="fa-solid fa-cart-shopping"></i></button>
            <p>Join our <b>5,000+</b> Users Worldwide  </p>
          </div>
        </div>
      </div>

      <div className='section3-container'>
        <div className='section3-contents'>
          <h1 className='section-header'>A Massive Upgrade</h1>
          <div className='whychooseus'>
            <div><OptionCard icon={stock} color={'white'} text={"Trades all Major pairs, indices &metal"} /></div>
            <div><OptionCard icon={stairs} color={'white'} text={"beginer friendly with 2 trading modes"} /></div>
            <div><OptionCard icon={trade} color={'white'} text={"Works on phone and laptop "} /></div>
            <div><OptionCard icon={wificonnection} color={'white'} text={"Works with any broker"} /></div>
            <div><OptionCard icon={livechat} color={'white'} text={"24/7 LIVE CHAT SUPPORT"} /></div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='section-4-contents'>
          <h1 className='section-header'>ITS TIME YOU TRADE LIKE A PRO,  Get Full Immediate Access To:</h1>
          <div>
            <TradingComponents title="The Modern way of trading"
              text="Choppa S Is A Automated Trading Robot That trades in live markets. Its new design makes it easier for beginners and professionals trades to start making consistent profits from the forex market . Equipped with the best scalping and swing options ,2 trading modes to Choose from , and trailing take profits and stop loss . It can be install on you phone or laptop to allowing you to simply trade from any part of the world."
              image={mordern}
            />
          </div>
          <div>
            <TradingComponents title="Beginner friendly"
              text="Its designed for professional traders and beginners too, it comes with preset and easy to follow instructions to make it as simple as installing and trade worry-free."
              image={friendly}
            />
          </div>

          <div>
            <TradingComponents title="Whats new"
              text="Our new design made it easy to allow 2 trading mode (normal & attack ).Normal mode is the preset settings to trade consistently and with very low risk. Attack mode trade aggressively by automatically increasing lot size while keeping low risk managment Additionally the trailing take profits and stop loss that moves it to profits when ever the market moves to the positive direction , all this to maximise profitability on each trades it makes"
              image={whatsnew}
            />
          </div>
          <div>
            <TradingComponents title="Our community"
              text={ourcommunitytext("A dedicated Channel that post Choppa S results")}
              image={service}
            />
          </div>

        </div>

      </div>

      {/* SECTION 5 */}
      <div className='section5'>

        <div className='section5-container'>
          <h1 className='section-header'>Join the new modern way of Make money smart, not hard</h1>
          <div>
            <ul>
              <li><i class="fa-regular fa-circle-check"></i> fully automated</li>
              <li><i class="fa-regular fa-circle-check"></i> Lifetime mentorship</li>
              <li><i class="fa-regular fa-circle-check"></i> 24/7 support and live chat</li>
              <li><i class="fa-regular fa-circle-check"></i> Access to VIP group</li>
              <li><i class="fa-regular fa-circle-check"></i> works with any broker</li>
              <li><i class="fa-regular fa-circle-check"></i> 98% winning rate</li>
              <li><i class="fa-regular fa-circle-check"></i> once off purchase</li>
            </ul>
          </div>
        </div>
      </div>


      <div className='section7'>
        <div className='section7-container'>
          <h2>get started today...</h2>
          <div><img src={choppas} alt='' /></div>
          <div>
            <h1>$300/once off</h1>
            <p>No monthly billing </p>
          </div>

          <div>
            <button className='siteButton hero-button' onClick={() => {
              dispatch(removeAllProduct())
              dispatch(addproduct(CHOPPA))
              window.location.assign("/checkout");
            }}>Get Started <i class="fa-solid fa-cart-shopping"></i></button>
          </div>
        </div>

      </div>


      <div className='section6-container'>
        <div className='section6-contents'>
          <h1 className='section-header'>Pricing</h1>
          <div className='pricing-container'>
            <div className='left'>
              <h2>CHOPPA S</h2>
              <h1>NOW $300</h1>
              <h4>ONE TIME PAYMENT</h4>
              <ul>
                <li>100% fully Automated Trading</li>
                <li>Lifetime mentorship</li>
                <li>Setup instructions</li>
                <li>Install on phone or pc </li>
                <li>License for both MT4 & MT5</li>
                <li>Access to VIP telegram group</li>
                <li>$20 minimum deposit </li>
                <li>Runs 24/7</li>
                <li>Use with any broker </li>
                <li>24/7 support </li>
              </ul>
              <button className='siteButton' onClick={() => {
                dispatch(removeAllProduct())
                dispatch(addproduct(CHOPPA))
                window.location.assign("/checkout");
              }}>BUY NOW</button>
            </div>
            <div className='right'>
              <h2>AUTOPILOT PRO PLUS</h2>
              <h1>$180</h1>
              <h4>ONE TIME PAYMENT</h4>
              <ul>
                <li>100% fully Automated Trading</li>
                <li>None</li>
                <li>Setup instructions</li>
                <li>Install on phone or pc </li>
                <li>License for mt4 and mt5 </li>
                <li>Private telegram group</li>
                <li>$30 minimum deposit </li>
                <li>Runs 24/7</li>
                <li>Works on most brokers  </li>
                <li>24/7 support </li>
              </ul>
              <button className='siteButton' onClick={() => {
                dispatch(removeAllProduct())
                dispatch(addproduct(autopilotpro))
                window.location.assign("/checkout");
              }}>BUY NOW</button>
            </div>
          </div>


        </div>
      </div>



      <div className='section8-container'>
        <div className='section8-contents'>
          <h1 className='section-header'>Frequently asked questions</h1>
          <div class="custom-details-group">
            <details>
              <summary>WHAT IS CHOPA<i class="fa-solid fa-chevron-right"></i></summary>
              <p>choppa is a easy-to-use automated trading robot that scans the market in real-time. Create a signal and execute a trade with take profit and stop loss on your behalf reducing the impact of emotions and human error </p>
            </details>

            <details>
              <summary>WHAT IS THE DIFFERENCE BETWEEN AUTOPILOT PRO AND CHOPPA <i class="fa-solid fa-chevron-right"></i></summary>
              <p>The new CHOPPA comes with free Mentorship , has trail Sl & tp and much better accuracy cause its designed to adapt to changing market conditions</p>
            </details>

            <details>
              <summary>IS IT OKAY WHEN THE CURRENCY CHANGES AT CHECK OUTPAGE <i class="fa-solid fa-chevron-right"></i></summary>
              <p>Yes thats totally normal since the store is currently priced in RANDS. this will not affect the pricing of our products/services. </p>
            </details>
            <details>
              <summary>WHAT IS THE MINIMUM DEPOSIT FOR CHOPPA TO WORK <i class="fa-solid fa-chevron-right"></i></summary>
              <p>We recommend starting with $20 or higher </p>
            </details>
            <details>
              <summary>IS THIS A MONTHLY SUBSCRIPTION? <i class="fa-solid fa-chevron-right"></i></summary>
              <p>No, there are no monthly or recurring fees, this is a one-time purchase, providing you with a lifetime license</p>
            </details>
          </div>

        </div>
      </div>


    </div>
  )
}     
